import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Img from 'gatsby-image'
import SEO from "../components/seo"
import { useForm } from '@formcarry/react'
import ReactMarkdown from "react-markdown"
import axios from "axios"

function MyFormcarry() {
  // Call the `useForm` hook in your function component
  const {state, submit} = useForm({
    id: 'NsVW0ldTdjVe'
  });
 
  // Success message
  if (state.submitted) {
    return <div><h3>Bedankt voor uw bericht!</h3><p>Het contactformulier werd goed verzonden. U krijgt binnen 24 uur antwoord (behalve in het weekend en tijdens vakanties).</p></div>;
  } else if (state.error) {
    return (
      <form id="contactform" onSubmit={submit}>
        <div className="form-group">
          <label htmlFor="voornaam">Voornaam</label>
          <input id="voornaam" type="input" name="voornaam" className="form-control" />
        </div>
        <div className="form-group">
          <label htmlFor="naam">Naam</label>
          <input id="naam" type="input" name="naam" className="form-control" />
        </div>
        <div className="form-group">
          <label htmlFor="tel">Telefoon</label>
          <input id="tel" type="input" name="tel" className="form-control" />
        </div>
        <div className="form-group">
          <label htmlFor="email">E-mail</label>
          <input id="email" type="email" name="email" className="form-control" />
        </div>
        <div className="form-group">
          <label htmlFor="bericht">Bericht</label>
          <textarea id="bericht" name="bericht" className="form-control" rows="4" />
        </div>
        <input type="hidden" name="_gotcha"></input>
        {/* <div className="g-recaptcha" data-sitekey="6LdUd7wZAAAAAN_m2hPlXJHGyCOL-G9GbTgEH02b"></div> */}
        <div id="requiredfields" className="alert alert-danger" role="alert">Gelieve minstens 1 veld in te vullen.</div>
        <div className="form-group">
          <button className="btn btn-primary mt-1 js-formsubmit" type="submit">Verstuur</button>
        </div>
      </form>
    );
  }
 
  return (
    <form id="contactform" onSubmit={submit}>
      <div className="form-group">
        <label htmlFor="voornaam">Voornaam</label>
        <input id="voornaam" type="input" name="voornaam" className="form-control" />
      </div>
      <div className="form-group">
        <label htmlFor="naam">Naam</label>
        <input id="naam" type="input" name="naam" className="form-control" />
      </div>
      <div className="form-group">
        <label htmlFor="tel">Telefoon</label>
        <input id="tel" type="input" name="tel" className="form-control" />
      </div>
      <div className="form-group">
        <label htmlFor="email">E-mail</label>
        <input id="email" type="email" name="email" className="form-control" />
      </div>
      <div className="form-group">
        <label htmlFor="bericht">Bericht</label>
        <textarea id="bericht" name="bericht" className="form-control" rows="4" />
      </div>
      <input type="hidden" name="_gotcha"></input>
      {/* <div className="g-recaptcha" data-sitekey="6LdUd7wZAAAAAN_m2hPlXJHGyCOL-G9GbTgEH02b"></div> */}
      <div className="form-group">
        <button className="btn btn-primary mt-1 js-formsubmit" type="submit">Verstuur</button>
      </div>
    </form>
  );
}

const ContactPage = ({ data }) => (
  <Layout>
    <SEO title={data.strapiContact.meta_titel} description={data.strapiContact.meta_omschrijving} lang="nl" />
    <div className="container">
      <div className="row">
        <div className="col-lg-9 mb-3">
          <h1>{data.strapiContact.titel}</h1>
          <ReactMarkdown source={data.strapiContact.tekst} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 col-lg-6 mb-3 mb-lg-5">
          <MyFormcarry></MyFormcarry>
        </div>
        <div className="col-lg-5 contact-gegevens offset-lg-1 mb-5">
          <ReactMarkdown source={data.strapiContact.contactgegevens} />
        </div>
      </div>
    </div>
    <iframe className="contact-map" width="100%" height="400" frameBorder="0" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDYHKrLbOkMnVwQEIP2rAlO6kG9JttX1zk&q=Lien+Van+Rompaey+-+Manuele+Kinesitherapie" allowFullScreen>
</iframe>
  </Layout>
)

export default ContactPage

export const query = graphql`
  query ContactQuery {
    strapiContact {
      titel
      tekst
      contactgegevens
      meta_titel
      meta_omschrijving
    }
  }
`